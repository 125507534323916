<template>
  <div>
    <div class="app-top">
      <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list>
    </div>
    <soft-list :btnDownload="true" :btnBuy="false" />
    <comm-desc />
  </div>
</template>

<script>
import API from '@/api/soft'
import swiperList from '@/components/swiperList'
// import moduleTitle from '@/components/moduleTitle'
// import commService from '@/components/commService'
// import commSay from '@/components/commSay'
import commDesc from '@/components/commDesc'
// import commFaq from '@/components/commFaq'
import softList from '@/components/softList'

export default {
  name: 'homeIndex',
  components: {
    swiperList,
    // moduleTitle,
    // commService,
    // commSay,
    commDesc,
    // commFaq,
    softList
  },
  data() {
    return {
      dataList: [],
      swiperList: [
        {
          image: '',
          title: '下载中心',
          subTitle: '支持Windows客户端、网页版、手机版，多端同步。',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-8.jpg')
        }
      ]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    this.getSoftList()
  },
  methods: {
    getSoftList() {
      API.getList({}).then(res => {
        let cates = []
        res.data.map(item => {
          let idx = -1
          cates.some((item1, index1) => {
            if (item1.name == item.cate) {
              idx = index1
              return true
            }
          })

          if (idx < 0) {
            cates.push({ name: item.cate, softs: [] })
            idx = cates.length - 1
          }
          cates[idx].softs.push(item)
        })
        console.log(cates)
        this.dataList = cates
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
}

.app-top {
  margin-top: 58px;
}
</style>